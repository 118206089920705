

import { employeeService } from "@/services/employee.service";
import EmployeeModel from "../models/employee";
import Company from "../models/company";
import { Component, Vue, Watch } from "vue-property-decorator";

import { roleService } from "@/services/role.service";
import { companyService } from "@/services/company.service";
import { Role } from '@/models/role';
import { VForm } from '@/types';
import { authService } from "@/services/authentification.service";
import ConfirmComponent from "@/components/ConfirmComponent.vue";
import {Filter, Sort} from "@/models/common";
import { DataOptions } from "vuetify";
import { debounce } from "@/utils/debounce";

@Component({
  components: { ConfirmComponent }
})
export default class Employees extends Vue {
  private employees: Array<EmployeeModel> = new Array<EmployeeModel>();
  private editedItem = new EmployeeModel();
  private defaultItem = new EmployeeModel();

  private loginBeforeChanges = "";

  private displayEditDialog = false;
  private displayConfirmDialog = false;
  private editedIndex = -1;
  private totalEmployees = 0;
  private loading = true;

  private roleLoading = true;
  private companyLoading = true;

  private valid = true;
  private loginErrors = "";

  private roles: Array<Role> = new Array<Role>();
  private companies: Array<Company> = new Array<Company>();

  private companyFilter = null;

  private saveLoading = false;
  private deleteLoading = false;

  private searchSurname = "";
  private searchCompany = "";

  private pagination = {
    page: 1,
    itemsPerPage: 50,
    pageStart: 0,
    pageStop: 50,
    pageCount: 0,
    itemsLength: 0,
  };

  private options: DataOptions = {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false
  };

  private headers = [
    { text: "Фирма", align: "start", sortable: false, value: "companies" },
    { text: "Фамилия", align: "start", sortable: true, value: "surname" },
    { text: "Имя", align: "start", sortable: true, value: "name" },
    { text: "Отчество", align: "start", sortable: true, value: "patronymic" },
    { text: "Роль", align: "start", sortable: false, value: "role.displayName" },
    { text: "Активный", align: "start", sortable: false, value: "activity" },
    { text: "Telegram", align: "start", sortable: false, value: "subscribedToTelegramNotifications" },
    { text: "Действия", value: "actions", sortable: false },
  ];

  getCompanies(companies: Array<Company>){
    let companiesString = "";
    companies.forEach(company => {
      if(companiesString == "")
        companiesString += company.name
        else
        companiesString += ", " +company.name
    });

    return companiesString;
  }

  @Watch("editedItem.login", { deep: true})
  async input(value: string) {
    if(!value || value === "" || value === this.loginBeforeChanges)
      return;
     const res = await employeeService.usernameExists(value);
     if (!res.data) {         
       this.loginErrors = "";       
     } else {
       this.loginErrors = "Этот логин занят";
     }
  }

  async created() {
    const employee = await authService.getCurrentUser();
    if (employee?.role?.name === "Root") {
      this.headers.unshift({text: "Ид", sortable: false, value: "id"});
      this.headers.unshift({ text: "Ид роли", align: "start", sortable: false, value: "roleId" });
    }

    await this.loadRoles();
    await this.loadCompanies("");
  }

  getFilters(){
    const filters = new Array<Filter>();
    if (this.searchCompany)
      filters.push({field: "company", value: this.searchCompany});
    
    if (this.searchSurname)
      filters.push({field: "surname", value: this.searchSurname});

    return filters;
  }

  getSorts(){
    const {sortBy, sortDesc} = this.options;

    const sortField = sortBy[0]
        ? sortBy[0].slice(0, 1).toUpperCase() + sortBy[0].slice(1)
        : null;
    const sortOrder = sortDesc[0] == false ? 1 : 0;
    const sorts = new Array<Sort>();
    if(sortField != null)
      sorts.push({field: sortField, order: sortOrder});
    return sorts;
  }

  @Watch("searchCompany")
  @Watch("searchSurname")
  @Watch("options", {deep: true})
  debouncedloadEmployees = debounce(this.loadEmployees, 500);

  async loadEmployees() {
    try {
      this.loading = true;

      const {page, itemsPerPage} = this.options;
      const offset = (page - 1) * itemsPerPage;
      const count = itemsPerPage == -1 ? this.totalEmployees : itemsPerPage;
      const sorts = this.getSorts();
      const filters = this.getFilters();

      this.pagination.pageStart = offset;
      this.pagination.pageStop = offset + itemsPerPage;

      const res = await employeeService.getPageData(offset, count, sorts, filters)
      this.employees = res.data.collection;
      this.totalEmployees = res.data.count;
      this.pagination.itemsLength = res.data.count;
      this.pagination.pageCount = Math.ceil(res.data.count / 50);
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.loading = false;
  }

  async loadRoles() {
    try {
      this.roleLoading = true;
      const res = await roleService.getRoles();
      this.roles = res.data;
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.roleLoading = false;
  }

  @Watch("companyFilter")
  debouncedloadCompanies = debounce(this.loadCompanies, 500);
  
  async loadCompanies(val: string) {
    try {
      this.companyLoading = true;
      const res = await companyService.getCompaniesForDropDown(val);
      this.companies = res.data;
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.companyLoading = false;
  }

  showEditDialog(item: EmployeeModel) {
    this.editedIndex = this.employees.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.loginBeforeChanges = this.editedItem.login;

    if(this.editedItem.companyId)
      this.companies = [{id: this.editedItem.companyId, name: this.editedItem.company, activity: true}];
      
    this.displayEditDialog = true;
  }

  showDeleteDialog(item: EmployeeModel) {
    this.editedIndex = this.employees.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.displayConfirmDialog = true;
  }

  async deleteEmployee() {
    try {
      this.deleteLoading = true;
      const item = await this.editedItem;
      await employeeService.delete(item.id);
      await this.loadEmployees();
    } catch (ex) {
      this.$toast.error("Ошибка при удалении");
    }
    this.deleteLoading = false;
    this.close();
  }

  close() {
    this.loginErrors = "";
    this.loginBeforeChanges = "";
    this.displayConfirmDialog = false;
    this.displayEditDialog = false;
    this.form?.resetValidation();
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  async save() {
    this.form?.validate();
    if (this.valid) {
      this.saveLoading = true;
      const index = this.editedIndex;
      try {
        if (index > -1) {
          await employeeService.update(this.editedItem);
        } else {
          await employeeService.create(this.editedItem);
        }
        await this.loadEmployees();
      }
      catch (ex) {
        this.$toast.error("Ошибка при сохранении");
      }
      this.saveLoading = false;
      this.close();
    }
  }
}
