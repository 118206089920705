export function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number
  ): (...args: Params) => void {
    let timer: number | null = null;
    let isFirstCall = true; // Флаг для отслеживания первого вызова
  
    return (...args: Params) => {
      if (isFirstCall) {
        func(...args); // Выполняем функцию без задержки при первом вызове
        isFirstCall = false;
        return;
      }
  
      if (timer) {
        clearTimeout(timer); // Очищаем предыдущий таймер
      }
  
      timer = window.setTimeout(() => {
        func(...args); // Вызываем функцию с задержкой
      }, timeout);
    };
  }