

import { VForm } from "@/types";
import { addressService } from "@/services/address.service";
import AddressModel from "../models/address";
import {Component, Vue, Watch} from "vue-property-decorator";
import Street from "@/models/street";
import City from "@/models/city";

import { cityService } from "@/services/city.service";
import { streetService } from "@/services/street.service";
import { authService } from "@/services/authentification.service";
import ConfirmComponent from "@/components/ConfirmComponent.vue";
import {Filter, Sort} from "@/models/common";
import { DataOptions } from "vuetify";
import { debounce } from "@/utils/debounce";

@Component({
  components: { ConfirmComponent }
})
export default class Address extends Vue {
  private addresses: Array<AddressModel> = new Array<AddressModel>();
  private editedItem = new AddressModel();
  private defaultItem = new AddressModel();

  private displayEditDialog = false;
  private displayConfirmDialog = false;
  private editedIndex = -1;
  private totalAddresses = 0;
  private loading = true;

  private valid = true;

  private streets: Array<Street> = new Array<Street>();
  private streetLoading = true;
  private streetFilter = null;

  private searchStreet = "";
  private searchHomeNumber = "";

  private cities: Array<City> = new Array<City>();
  private cityLoading = true;
  private cityFilter = null;

  private saveLoading = false;
  private deleteLoading = false;

  private pagination = {
    page: 1,
    itemsPerPage: 50,
    pageStart: 0,
    pageStop: 50,
    pageCount: 0,
    itemsLength: 0,
  };

  private options: DataOptions = {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false
  };

  private headers = [
    { text: "Город", align: "start", sortable: true, value: "city" },
    { text: "Улица", align: "start", sortable: true, value: "street" },
    { text: "Номер дома", align: "start", sortable: false, value: "homeNumber" },
    { text: "Действия", value: "actions", sortable: false },
  ];

  async created() {
    const employee = await authService.getCurrentUser();
    if (employee?.role?.name === "Root") {
      this.headers.unshift({text: "Ид", sortable: false, value: "id"});
      this.headers.unshift({ text: "Ид города", align: "start", sortable: false, value: "cityId" });
      this.headers.unshift({ text: "Ид Улицы", align: "start", sortable: false, value: "streetId" });
    }

    await this.loadCities("");
    await this.loadStreets("");
  }

  getFilters(){
    const filters = new Array<Filter>();
    if (this.searchStreet)
      filters.push({field: "street", value: this.searchStreet});

    if (this.searchHomeNumber)
      filters.push({field: "homeNumber", value: this.searchHomeNumber});

    return filters;
  }

  getSorts(){
    const {sortBy, sortDesc} = this.options;

    const sortField = sortBy[0]
        ? sortBy[0].slice(0, 1).toUpperCase() + sortBy[0].slice(1)
        : null;
    const sortOrder = sortDesc[0] == false ? 1 : 0;
    const sorts = new Array<Sort>();
    if(sortField != null)
      sorts.push({field: sortField, order: sortOrder});
    return sorts;
  }

  @Watch("searchStreet")
  @Watch("searchHomeNumber")
  @Watch("options", {deep: true})
  debouncedloadAddresses = debounce(this.loadAddresses, 500);

  async loadAddresses() {
    try {
      this.loading = true;

      const {page, itemsPerPage} = this.options;
      const offset = (page - 1) * itemsPerPage;
      const count = itemsPerPage == -1 ? this.totalAddresses : itemsPerPage;
      const sorts = this.getSorts();
      const filters = this.getFilters();

      this.pagination.pageStart = offset;
      this.pagination.pageStop = offset + itemsPerPage;

      const res = await addressService.getPageData(offset, count, sorts, filters);
      this.addresses = res.data.collection;
      this.totalAddresses = res.data.count;
      this.pagination.itemsLength = res.data.count;
      this.pagination.pageCount = Math.ceil(res.data.count / 50);
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.loading = false;
  }

  @Watch("cityFilter")
  debouncedloadCities = debounce(this.loadCities, 500);
  
  async loadCities(val: string) {
    try {
      this.cityLoading = true;
      const res = await cityService.getCitiesForDropDown(val);
      this.cities = res.data;
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.cityLoading = false;
  }

  @Watch("streetFilter")
  debouncedloadStreets = debounce(this.loadStreets, 500);

  async loadStreets(val: string) {
    try {
      this.streetLoading = true;
      const res = await streetService.getStreetsForDropDown(val);
      this.streets = res.data;
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.streetLoading = false;
  }

  async showEditDialog(item: AddressModel) {
    this.editedIndex = this.addresses.indexOf(item);
    this.editedItem = Object.assign({}, item);
    //сделать вариант подгрузки по id и по имени
    //так же заиспользовать Filter для этого в апи
    //await this.loadStreets(this.editedItem.street);

    this.cities = [{id: this.editedItem.cityId, name: this.editedItem.city}];
    this.streets = [{id: this.editedItem.streetId, name: this.editedItem.street}];
    this.displayEditDialog = true;
  }

  showDeleteDialog(item: AddressModel) {
    this.editedIndex = this.addresses.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.displayConfirmDialog = true;
  }

  async deleteAddress() {
    try {
      this.deleteLoading = true;
      const item = this.editedItem;
      await addressService.delete(item.id)
      await this.loadAddresses();
    }
    catch (ex) {
      this.$toast.error("Ошибка при удалении");
    }
    this.deleteLoading = false;
    this.close();
  }

  close() {
    this.displayConfirmDialog = false
    this.displayEditDialog = false;
    this.form?.resetValidation();
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  async save() {
    this.form?.validate();
    if (this.valid) {
      this.saveLoading = true;
      const index = this.editedIndex;
      try {
        if (index > -1) {
          await addressService.update(this.editedItem);
        } else {
          await addressService.create(this.editedItem);
        }
        await this.loadAddresses();
      }
      catch (ex) {
        this.$toast.error("Ошибка при сохранении");
      }
      this.saveLoading = false;
      this.close();
    }
  }
}
