

import { contactService } from "@/services/contact.service";
import Contact from "../models/contact";
import {Component, Vue, Watch} from "vue-property-decorator";
import { VForm } from '@/types';
import { authService } from "@/services/authentification.service";
import ConfirmComponent from "@/components/ConfirmComponent.vue";
import {Filter, Sort} from "@/models/common";
import City from '@/models/city';
import Company from '@/models/company';
import { cityService } from '@/services/city.service';
import { companyService } from '@/services/company.service';
import { accessSettings } from '@/settings/access.settings';
import { DataOptions } from "vuetify";
import { debounce } from "@/utils/debounce";

@Component({
  components: { ConfirmComponent }
})
export default class Contacts extends Vue {
  private contacts: Array<Contact> = new Array<Contact>();
  private editedItem = new Contact();
  private defaultItem = new Contact();

  private displayEditDialog = false;
  private displayConfirmDialog = false;
  private editedIndex = -1;
  private totalContacts = 0;
  private loading = true;

  private pagination = {
    page: 1,
    itemsPerPage: 50,
    pageStart: 0,
    pageStop: 50,
    pageCount: 0,
    itemsLength: 0,
  };

  private options: DataOptions = {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false
  };

  private saveLoading = false;
  private deleteLoading = false;

  private selectedCity: any = null;
  private cities: Array<City> = new Array<City>();
  private cityLoading = true;
  private cityFilter = "";

  private selectedCompany: any = null;
  private companies: Array<Company> = new Array<Company>();
  private companyLoading = true;
  private companyFilter = "";
  private searchCompany = "";

  private headers = [
    { text: "Город", value: "city", sortable: true },
    { text: "Фирма", value: "company", sortable: true },
    { text: "Должность", value: "position", sortable: true },
    { text: "ФИО", value: "fullName", sortable: true },
    { text: "Номер", value: "phoneNumber", sortable: false },
    { text: "Комментарий", value: "comment", sortable: false },
    //{ text: "Действия", value: "actions", sortable: false },
  ];

  private enabledEdit = false;

  private valid = true;

  async created() {
    const employee = await authService.getCurrentUser();
    if (employee?.role?.name === "Root") {
      this.headers.unshift({text: "Ид", sortable: false, value: "id"});
    }

    this.enabledEdit = await accessSettings.canAccess("editContacts");

    if (this.enabledEdit) {
      this.headers.push({text: "Действия", value: "actions", sortable: false,});
    }
  }

  @Watch("cityFilter")
  debouncedloadCities = debounce(this.loadCities, 500);

  async loadCities(val: string) {
    try {
      this.cityLoading = true;
      const res = await cityService.getCitiesForDropDown(val);
      this.cities = res.data;
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.cityLoading = false;
  }

  @Watch("companyFilter")
  debouncedloadCompanies = debounce(this.loadCompanies, 500);

  async loadCompanies(val: string) {
    try {
      this.companyLoading = true;
      const res = await companyService.getCompaniesForDropDown(val);
      this.companies = res.data;
    } catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.companyLoading = false;
  }

  getFilters(){
    const filters = new Array<Filter>();
    if (this.searchCompany)
      filters.push({field: "company", value: this.searchCompany});

    return filters;
  }

  getSorts(){
    const {sortBy, sortDesc} = this.options;

    const sortField = sortBy[0]
        ? sortBy[0].slice(0, 1).toUpperCase() + sortBy[0].slice(1)
        : null;
    const sortOrder = sortDesc[0] == false ? 1 : 0;
    const sorts = new Array<Sort>();
    if(sortField != null)
      sorts.push({field: sortField, order: sortOrder});
    return sorts;
  }

  @Watch("searchCompany")
  @Watch("options", {deep: true})
  debouncedloadContacts = debounce(this.loadContacts, 500);

  async loadContacts() {
    try {
      this.loading = true;
      const {page, itemsPerPage} = this.options;
      const offset = (page - 1) * itemsPerPage;
      const count = itemsPerPage == -1 ? this.totalContacts : itemsPerPage;
      const sorts = this.getSorts();
      const filters = this.getFilters();

      this.pagination.pageStart = offset;
      this.pagination.pageStop = offset + itemsPerPage;

      const res = await contactService.getPageData(offset, count, sorts, filters);
      this.contacts = res.data.collection;
      this.totalContacts = res.data.count;
      this.pagination.itemsLength = res.data.count;
      this.pagination.pageCount = Math.ceil(res.data.count / 50);
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.loading = false;
  }

  showEditDialog(item: Contact) {
    this.editedIndex = this.contacts.indexOf(item);
    this.editedItem = Object.assign({}, item);

    this.selectedCity = this.editedItem.city;
    this.selectedCompany = this.editedItem.company;

    this.displayEditDialog = true;
  }

  showDeleteDialog(item: Contact) {
    this.editedIndex = this.contacts.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.displayConfirmDialog = true;
  }

  async deleteContact() {
    try {
      this.deleteLoading = true;
      const item = this.editedItem;
      await contactService.delete(item.id);
      await this.loadContacts();
    }
    catch (ex) {
      this.$toast.error("Ошибка при удалении");
    }
    this.deleteLoading = false;
    this.close();
  }

  close() {
    this.displayConfirmDialog = false;
    this.displayEditDialog = false;
    this.selectedCity = null;
    this.selectedCompany = null;
    this.form?.resetValidation();
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  async save() {
    this.form?.validate();
    if (this.valid) {
      this.saveLoading = true;
      const index = this.editedIndex;
      this.editedItem.city = this.selectedCity?.name ?? this.cityFilter;
      this.editedItem.company = this.selectedCompany?.name ?? this.companyFilter;
      try {
        if (index > -1) {
          await contactService.update(this.editedItem);
        } else {
          await contactService.create(this.editedItem);
        }
        await this.loadContacts();
      }
      catch (ex) {
          this.$toast.error("Ошибка при сохранении");
      }
      this.saveLoading = false;
      this.close();
    }
  }
}
