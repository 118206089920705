

import { Component, Vue, Watch } from "vue-property-decorator";
import BidModel from "../models/bid";

import { VForm } from "@/types";
import TypeFailure from "@/models/type-failure";
import { ObjectSearchResult } from "@/models/object";
import Employee from "@/models/employee";
import { typeFailureService } from "@/services/type-failure.service";
import { reasonFailureService } from "@/services/reason-failure.service";
import { objectService } from "@/services/object.service";
import { employeeService } from "@/services/employee.service";
import { authService } from "@/services/authentification.service";
import { bidService } from "@/services/bid.service";
import moment from "moment";
import ImageLoader, { ImagesModel } from '@/components/ImageLoader.vue';
import CommentsComponent from '@/components/CommentsComponent.vue';
import { CommentModelComponent } from '@/components/CommentComponent.vue';
import ReasonFailure from "@/models/reason-failure";
import {accessSettings} from "@/settings/access.settings";
import {Filter, SearchResults} from "@/models/common";
import {Role} from "@/models/role";
import {roleService} from "@/services/role.service";
import axiosInstance from '../services/index';
import { debounce } from "@/utils/debounce";

@Component({
  components: {ImageLoader, CommentsComponent}
})
export default class BidEdit extends Vue {
  private editedItem = new BidModel();

  //содержит все изображения для заявки
  private images = new Array<ImagesModel>();

  //комментарии
  private comments = new Array<CommentModelComponent>();

  private biddate = moment().local().format("YYYY-MM-DD, HH:mm").substr(0, 10);
  private bidtime = moment().local().format("YYYY-MM-DD, HH:mm").substr(12, 17);
  private biddatemenu = false;
  private bidtimemenu = false;

  private biddatestart = "";
  private bidtimestart = "";
  private biddatemenustart = false;
  private bidtimemenustart = false;

  private biddaterelease = "";
  private bidtimerelease = "";
  private biddatemenurelease = false;
  private bidtimemenurelease = false;

  private biddateend = "";
  private bidtimeend = "";
  private biddatemenuend = false;
  private bidtimemenuend = false;

  private typeFailures: Array<TypeFailure> = new Array<TypeFailure>();
  private failureLoading = false;
  private typeFailureFilter = null;

  private objects = new Array<ObjectSearchResult>();
  private objectsLoading = false;
  private searchObjectInput = null;

  private executors = new Array<Employee>();
  private loadingExecutors = false;
  private searchExecutorsInput = null;
  private valid = true;

  private defaultIserId = 0;
  private curentEmployee: Employee;

  private saveLoading = false;
  private showState = false;

  private reasonFailures: Array<ReasonFailure> = new Array<ReasonFailure>();
  private reasonFailuresLoading = false;
  private reasonFailure: any = null;
  private reasonFailureFilter: any = null;

  private roles: Array<Role> = new Array<Role>();
  private selectedRole: Role | null | undefined = null;
  private roleLoading = false;

  private canEditBaseInfo = false;
  private createBidWithoutExecutor= false;
  private canConfirmBid = false;

  get getBidNumber(){
    if(this.editedItem.id)
      return "№" + this.editedItem.id;
    return "";
  }

  canCreateBidWithoutExecutor(){
    if(this.createBidWithoutExecutor || this.editedItem.executorId)
      return true;
    return false;
  }

  confirmBid(){
    if(this.canConfirmBid){
      this.editedItem.isCompleted = !this.editedItem.isCompleted;
    }
  }

  get getColorState () {
    if(this.editedItem.isCompleted) return 'green'; //завершена
    else if(this.biddateend) return 'info';      //ожидает подтверждения
    else if (this.biddatestart) return 'orange'; //в работе
    else return 'red';               //новая
  }

  get getLabelState () {
    if(this.editedItem.isCompleted) return 'Завершена'; //завершена
    else if(this.biddateend) return 'Ожидает подтверждения';      //ожидает подтверждения
    else if (this.biddatestart) return 'В работе'; //в работе
    else return 'Новая';               //новая
  }

  async mounted() {
    try {
      //добавляем текущего пользователя в дефолтную заявку
      const employee = await authService.getCurrentUser();
      this.defaultIserId = employee.id;
      this.curentEmployee = employee;

      this.canEditBaseInfo = await accessSettings.canAccess("bidsEditBaseInfo");
      this.createBidWithoutExecutor= await accessSettings.canAccess("createBidWithoutMechanic");
      this.canConfirmBid = await accessSettings.canAccess("bidsConfirm");

      if (this.$router.currentRoute.params.id != "new") {
        const res = await bidService.getSingle(this.$router.currentRoute.params.id);

        if (res.data.photos) {
          for (let index = 0; index < res.data.photos.length; index++) {
            const element = res.data.photos[index];
            const img = new ImagesModel();
            img.id = element.id;
            img.previewImage = axiosInstance.getUri({ url: element.path });
            this.images.push(img);
          }
        }

        for (let index = 0; index < res.data.comments.length; index++) {
          const element = res.data.comments[index];
          const comment = new CommentModelComponent();
          comment.id = element.id;
          comment.bidId = element.bidId;
          comment.employeeId = element.employeeId;
          comment.employee = element.employee;
          comment.text = element.text;
          comment.dateTime = element.dateTime;
          this.comments.push(comment);
        }

        this.editedItem = res.data;
        this.reasonFailure = this.editedItem.reasonFailure;
        //нужно выставить все значения для формы из модели
        const bdate = moment.utc(this.editedItem.dateTime).local();
        if (bdate.isValid()) {
          this.biddate = bdate.format("YYYY-MM-DD");
          this.bidtime = bdate.format("HH:mm");
        }

        const sdate = moment.utc(this.editedItem.dateTimeStart).local();
        if (sdate.isValid()) {
          this.biddatestart = sdate.format("YYYY-MM-DD");
          this.bidtimestart = sdate.format("HH:mm");
        }

        const rdate = moment.utc(this.editedItem.releaseDateTimePassenger).local();
        if (rdate.isValid()) {
          this.biddaterelease = rdate.format("YYYY-MM-DD");
          this.bidtimerelease = rdate.format("HH:mm");
        }

        const edate = moment.utc(this.editedItem.dateTimeFinished).local();
        if (edate.isValid()) {
          this.biddateend = edate.format("YYYY-MM-DD");
          this.bidtimeend = edate.format("HH:mm");
        }

        //если это обновление то нужно подтянуть значения для autocomplete
        //this.objects = [{id: this.editedItem.objectId, fullName: this.editedItem.object}];
        //this.mechanics = [{id: this.editedItem.mechanicId, fullName: this.editedItem.mechanic}];
        //this.typeFailures = [{id: this.editedItem.typeFailureId, category: this.editedItem.typeFailure}];
        //await this.loadObjects(this.editedItem.object);
        //await this.loadObjects(null, this.editedItem.objectId);
        if(this.editedItem.objectId)
        await this.loadObjectsById(this.editedItem.objectId);
        await this.loadRoles();
        //для уже существующей
        await this.loadTypeFailures(this.editedItem.typeFailure ?? '');
        if(!this.editedItem.reasonFailure)
          await this.loadReasonFailures("");
        this.showState = true;
      } else {
        //для новой
        await this.loadObjects("");
        await this.loadTypeFailures("");
        await this.loadReasonFailures("");
        await this.loadRoles();

        this.showState = true;
      }
      if(this.editedItem.executorRoleId){
        this.selectedRole = this.roles.find((d) => d.id === this.editedItem.executorRoleId);
        await this.loadExecutors(this.editedItem.executor ?? '');
      }
      else{
        //для новой по дефолту роль механник
        this.selectedRole = this.roles.find((d) => d.displayName === "Механник");
      }

    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
  }

  addComment(text: string){
    const comment = new CommentModelComponent();
    comment.text = text;
    comment.dateTime = new Date();
    comment.employeeId = this.curentEmployee.id;
    comment.employee = this.curentEmployee.surname +" "+ this.curentEmployee.name + " " + this.curentEmployee.patronymic;
    comment.bidId = this.editedItem.id;
    this.comments.push(comment);
  }

  async loadRoles() {
    try {
      this.roleLoading = true;
      const res = await roleService.getRoles();
      this.roles = res.data.filter(r => (r.displayName == "Механник" || r.displayName == "Прораб" || r.displayName == "Инженер-наладчик"));
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.roleLoading = false;
  }

  @Watch("typeFailureFilter")
  debouncedloadTypeFailures = debounce(this.loadTypeFailures, 500);

  async loadTypeFailures(val: string) {
    this.failureLoading = true;
    const res = await typeFailureService.getTypeFailuresForDropDown(val);
    this.typeFailures = res.data;
    this.failureLoading = false;
  }

  @Watch("reasonFailureFilter")
  debouncedloadReasonFailures = debounce(this.loadReasonFailures, 500);

  async loadReasonFailures(val: string) {
    this.reasonFailuresLoading = true;
    const res = await reasonFailureService.getReasonFailureForDropDown(val);
    this.reasonFailures = res.data;
    this.reasonFailuresLoading = false;
  }

  //после подгрузки по id сразу повторно вызывается ненужная подгрузка по имени
  //чтобы избежать этого используется костыль с переменной ниже.
  //избавиться от этого в дальнейшем
  private disableNextUpdate = false;

  @Watch("searchObjectInput")
  debouncedloadObjects = debounce(this.loadObjects, 500);

  async loadObjects(val: string) {
    if(this.objectsLoading) return;

    this.objectsLoading = true;
    //поиск по имени если ищем

    if(this.disableNextUpdate){
      this.objectsLoading = false;
      this.disableNextUpdate= false;
      return;
    }

    const filters = new Array<Filter>();
    filters.push({field: "Value", value: val});
    const res = await objectService.getSearchResult(filters);
    this.objects = res.data;

    this.objectsLoading = false;
  }

  async loadObjectsById(id: number){
    this.objectsLoading = true;
    this.disableNextUpdate = true;

    const filters = new Array<Filter>();
    filters.push({field: "Id", value: id.toString()});
    const res = await objectService.getSearchResult(filters);
    this.objects = res.data;
    this.objectsLoading = false;
  }

  @Watch("searchExecutorsInput")
  debouncedloadExecutors = debounce(this.loadExecutors, 500);

  async loadExecutors(val: string) {
    this.loadingExecutors = true;
    const object = this.objects.find((d) => d.id === this.editedItem.objectId);
    //нужно получать тех кто выбран по роли
    const res = await employeeService.getEmployeesForDropDown(this.selectedRole?.name, object?.companyId?.toString(), val);
    this.executors = res.data;
    this.loadingExecutors = false;
  }

  //при смене объекта или роли нужно сбросить механника
  changeObject() {
    this.editedItem.executorId = undefined;
    this.loadExecutors("");
  }

  close() {
    this.$router.push({ path: "/bids" });
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  getTimeString(time: string){
    if(time.length > 0){
      return "T" + time + ":00";
    }
    return "";
  }

  getDate(date: string, time: string){
    let result = "";
    if(date != null && date.length > 0){
      result += date;
    }

    if(time != null && time.length > 0){
      result += this.getTimeString(time);
    }

    const resultDate = moment(result);
    if(resultDate.isValid()){
      //получаем смещение относительно utc в часах
      //console.log(new Date().getTimezoneOffset()/60*(-1));
      return resultDate.toDate();
    }
    else
      return undefined;
  }

  async save() {
    this.form?.validate();
    if (this.valid) {
      this.saveLoading = true;
      const item = this.editedItem;
      //нужно выставить все значения в модели из формы
      //дата может быть не валидной
      item.dateTime = this.getDate(this.biddate, this.bidtime);

      item.dateTimeStart = this.getDate(this.biddatestart, this.bidtimestart);

      item.releaseDateTimePassenger = this.getDate(this.biddaterelease, this.bidtimerelease);

      item.dateTimeFinished = this.getDate(this.biddateend, this.bidtimeend);

      this.editedItem.reasonFailure = this.reasonFailureFilter;
      //console.log(new Date().toISOString());
      try {
        if (this.editedItem.id) {
          await bidService.update(item);
          await this.saveImages(this.editedItem.id);
          await this.saveComments(this.editedItem.id);
        } else {
          item.employeeId = this.defaultIserId;
          const res = await bidService.create(item);
          await this.saveImages(res.data.id);
          await this.saveComments(res.data.id);
          this.$toast.success("Заявка номер: " +res.data.id+ " создана");
        }
      }
      catch (ex) {
        this.$toast.error("Ошибка при сохранении");
      }
      this.saveLoading = false;
      this.close();
    }
  }

  canEdit(comment: CommentModelComponent){
    if(this.curentEmployee){
       return comment.employeeId === this.curentEmployee.id;
    }
    return false;
  }

  async saveImages(bidId: number) {
    const photosWithId = this.images.map(image => image.id);
    const photosFromBid = this.editedItem.photos?.map(photo => photo.id);
    //найти разницу между массивами
    //это то что нужно удалить
    const photosForRemove = photosFromBid?.filter(id => photosWithId.indexOf(id) == -1);

    if(photosForRemove && photosForRemove.length > 0)
      await bidService.deletePhotos(photosForRemove);

    //добавить новые
    const newPhotos = this.images
        .map(im => im.file)
        .filter((file): file is File => file !== null);
        
    if(newPhotos && newPhotos.length > 0)
      await bidService.savePhotos(bidId, newPhotos);
  }

  async saveComments(bidId: number) {
    const commentsWithId = this.comments.filter(comment => comment.id).map(comment => comment.id);
    const commentsFromBid = this.editedItem.comments?.map(comment => comment.id);

    const commentsForRemove = commentsFromBid?.filter(id => commentsWithId.indexOf(id) == -1);

    //удаляем удаленные
    if(commentsForRemove && commentsForRemove.length > 0)
      {
        commentsForRemove.map(async comment =>{
           await bidService.removeComment(comment);
        })
      }

    //обновляем измененные
    const changedComments = this.comments.filter(comment => comment.isChanged);
    if(changedComments && changedComments.length > 0)
      {
        changedComments.map(async comment =>{
           await bidService.updateComment(comment);
        })
      }

    //добавляем новые
     const newComments = this.comments.filter(im => !im.id).map(im => im);
    if(newComments && newComments.length > 0)
      {
        newComments.map(async comment =>{
          comment.bidId = bidId;
           await bidService.addComment(comment);
        })
      }
  }

}
