

import { bidService } from "@/services/bid.service";
import Lightbox from 'vue-easy-lightbox';
import BidModel from "../models/bid";
import { Component, Vue, Watch } from "vue-property-decorator";
import {Filter, Sort} from "@/models/common";
import moment from 'moment';
import "moment/locale/ru";
import { VForm } from '@/types';
import BidPhoto from '@/models/bid-photo';
import ConfirmComponent from "@/components/ConfirmComponent.vue";
import {accessSettings} from "@/settings/access.settings";
import TypeFailure from "@/models/type-failure";
import { typeFailureService } from "@/services/type-failure.service";
import CommentsComponent from "@/components/CommentsComponent.vue";
import CommentComponent from "@/components/CommentComponent.vue";
import { DataOptions } from "vuetify";
import axiosInstance from '../services/index';
import { debounce } from "@/utils/debounce";

enum BidState { All=0, New=1, InProgress=2, Unconfirmed=3, Closed=4}

@Component({
  components: { Lightbox, ConfirmComponent, CommentComponent}
})
export default class Bids extends Vue {
  private bids: Array<BidModel> = new Array<BidModel>();
  private editedItem = new BidModel();
  private defaultItem = new BidModel();

  private displayConfirmDialog = false;
  private editedIndex = -1;
  private totalBids = 0;
  private loading = true;

  private visibleImageViewer = false;
  private imgs = new Array<string>();
  private index = 0;

  private pagination = {
    page: 1,
    itemsPerPage: 50,
    pageStart: 0,
    pageStop: 50,
    pageCount: 0,
    itemsLength: 0,
  }

  private deleteLoading = false;

  private options: DataOptions = {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false
  };
  
  private headers = [
    { text: "Номер заявки", align: "start", sortable: true, value: "id" },
    { text: "Дата и время", value: "dateTime", sortable: true },
    { text: "Начало работ", value: "dateTimeStart", sortable: true },
    { text: "Освобождение пассажира", value: "releaseDateTimePassenger", sortable: true },
    { text: "Окончание работ", value: "dateTimeFinished", sortable: true },
    { text: "Объект", value: "object", sortable: true },
    // { text: "Закрепленный механник", value: "objectMechanic", sortable: true },
    // { text: "Закрепленный прораб", value: "objectForeman", sortable: true },
    { text: "Категория неисправности", value: "typeFailure", sortable: true },
    { text: "Причина неисправности", value: "reasonFailure", sortable: false },
    { text: "Исполнитель", value: "executor", sortable: true },
    { text: "Действия", value: "actions", sortable: false },
    { text: '', value: 'data-table-expand' }
  ];

  private yearItems = new Array<string>();
  private yearSelected: any = null;
  private currentYear = new Date().getFullYear().toString();

  private mounthItems = ["Все", "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
  private mounthSelected: any = null;
  private currentMounth = (this.mounthItems[new Date().getMonth() + 1]).toString();

  private loadingExcel = false;

  private bidCompanyFilter = "";
  private bidObjectFilter = "";

  private executorFilter = "";

  private stateItems = ["Все", "Новые", "В работе", "Неподтвержденные", "Закрытые"];
  private stateSelected: any = null;
  private currentState = "Все";

  private canAddBid = false;
  private showFirmFilter = false;

  private typeFailures = new Array<TypeFailure>();
  private typeFailureSelected: any = null;
  private loadingTypeFailures = false;
  private currentTypeFailure = null;

  private displayFilterDialog = false;

  get getYear(){
    if(this.yearSelected == "Все")
      return null;
    if(this.yearSelected)
      return this.yearSelected;
    return this.currentYear;
  }

  get getMounthNumber(){
    if(this.mounthSelected){
      const selectedMounth = this.mounthItems.indexOf(this.mounthSelected);
      if(selectedMounth == 0)
        return null;

      return selectedMounth;
    }
     
    return this.mounthItems.indexOf(this.currentMounth);
  }

  generateYears(){
    let currentYear = new Date().getFullYear();
    //дата с которой начался учет
    const startYear = 2020;
    const years = ["Все"];

    if(currentYear == startYear){
      years.push(currentYear.toString());
    }
    else{
      for (; currentYear >= startYear; currentYear--) {
        years.push(currentYear.toString());
      }
    }

    this.yearItems = years;
  }

  async getTypesFailures(){
    const result = await typeFailureService.getTypeFailuresForDropDown("");
    this.typeFailures = result.data;
  }

  async created() {
    this.getTypesFailures();

    this.generateYears();
    if (this.$router.currentRoute.query.state === "newbids") {
      this.stateSelected = this.stateItems[1];
    }
    if (this.$router.currentRoute.query.state === "inProgress") {
      this.stateSelected = this.stateItems[2];
    }
    if (this.$router.currentRoute.query.state === "unconfirmed") {
      this.stateSelected = this.stateItems[3];
    }
    if (this.$router.currentRoute.query.state === "closed") {
      this.stateSelected = this.stateItems[4];
    }

    if(this.$router.currentRoute.query.year){
      this.yearSelected = this.$router.currentRoute.query.year;
    }
    if(this.$router.currentRoute.query.mounth){
      this.mounthSelected = this.$router.currentRoute.query.mounth;
    }

    if(this.$router.currentRoute.query.typeFailureId){
      const typeFailureId = parseInt(this.$router.currentRoute.query.typeFailureId as string, 10);
      const typeFailure = this.typeFailures.find(item => item.id == typeFailureId)
      this.typeFailureSelected = typeFailure;
    }
 
    this.showFirmFilter = await accessSettings.canAccess("showFirmFilter");
    this.canAddBid = await accessSettings.canAccess("bidsAdd");

    if(this.showFirmFilter){
      this.headers.splice(5, 0, { text: "Фирма", value: "objectCompany", sortable: true });
    }
  }

  handleHide () {
    this.visibleImageViewer = false;
  }

  showImageView(photos: Array<BidPhoto>, key: number){
    console.log("showImageView");
    this.imgs = [];
    for (let index = 0; index < photos.length; index++) {
      const element = photos[index];
      this.imgs.push(this.getImageUrl(element.path));
    }
    this.index = key;
    this.visibleImageViewer = true;
  }

  getImageUrl(path: string){
    return axiosInstance.getUri({ url: path });
  }

  ChangeFilter(){
      this.loadBids();
  }

  getColor (start: any, end: any, isCompleted: any) {
        if(isCompleted) return 'green'; //завершена
        else if(end) return 'info'      //ожидает подтверждения
        else if (start) return 'orange' //в работе
         // - нужно подтверждение
        else return 'red'               //новая
  }

  getBidFilters(){
    const year = this.getYear;
    const month = this.getMounthNumber;

    const filters = new Array<Filter>();
    if (this.bidCompanyFilter)
      filters.push({field: "company", value: this.bidCompanyFilter});

    if (this.bidObjectFilter)
      filters.push({field: "object", value: this.bidObjectFilter});

    if(year)
      filters.push({field: "year", value: year});

    if(month)
      filters.push({field: "month", value: month?.toString()});

    const state = this.stateItems.indexOf(this.stateSelected);
    filters.push({field: "state", value: BidState[state]});

    if(this.typeFailureSelected){
      filters.push({field: "typeFailureId", value: this.typeFailureSelected.id.toString()});
    }

    if(this.executorFilter){
      filters.push({field: "executor", value: this.executorFilter});
    }

    return filters;
  }

  getSorts(){
    const {sortBy, sortDesc} = this.options;

    const sortField = sortBy[0] ? sortBy[0].slice(0, 1).toUpperCase() + sortBy[0].slice(1) : null;
    const sortOrder = sortDesc[0] == false ? 1 : 0;

    const sorts = new Array<Sort>();
    if(sortField != null)
      sorts.push({field: sortField, order: sortOrder});

    return sorts;
  }

  async excelExport() {
    this.loadingExcel = true;

    const filters = this.getBidFilters();
    const sorts = this.getSorts();

    try {
      const response = await bidService.exportExcel(0, 0, sorts, filters);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Заявки.xlsx');
      document.body.appendChild(link);
      link.click();
    }
    catch (ex) {
      this.$toast.error("Ошибка при экспорте");
    }
    this.loadingExcel = false;
  }

  @Watch("bidCompanyFilter")
  @Watch("executorFilter")
  @Watch("bidObjectFilter")
  @Watch('options', {deep: true})
  debouncedLoadBids = debounce(this.loadBids, 500);

  async loadBids() {
    const {page, itemsPerPage} = this.options;

    this.loading = true;
    const offset = (page - 1) * itemsPerPage;
    const count = itemsPerPage == -1 ? this.totalBids : itemsPerPage;
    const filters = this.getBidFilters();
    const sorts = this.getSorts();

    this.pagination.pageStart = offset;
    this.pagination.pageStop = offset + itemsPerPage;

    try {
      const res = await bidService.getPageData(offset, count, sorts, filters);
      this.totalBids = res.data.count;
      this.bids = res.data.collection;
      this.pagination.itemsLength = res.data.count;
      this.pagination.pageCount = Math.ceil(res.data.count / 50);

      //после применения фильтра если данных меньше чем текущая страница
      //вызовет повторную загрузку данных
      if(this.options.page > this.pagination.pageCount){
          this.options.page = 1;
      }

    } catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.loading = false;
  }

  private convertToTimeZone(date: Date) {
    const utcDate = moment.utc(date);
    if(utcDate.isValid()){
      //получаем смещение относительно utc в часах
      //console.log(new Date().getTimezoneOffset()/60*(-1));
      return utcDate.local().format("DD.MM.YYYY, HH:mm");
    }
    else
    return "";
	}

  changeBidsFilter(){
    this.loadBids();
  }

  showEditDialog(item: BidModel) {
    this.editedIndex = this.bids.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.$router.push({ path: '/bidsEdit/' + this.editedItem.id  })
  }

  showDeleteDialog(item: BidModel) {
    this.editedIndex = this.bids.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.displayConfirmDialog = true;
  }

  async deleteBid() {
    try {
      this.deleteLoading = true;
      const item = this.editedItem;
      if(item.id){
        await bidService.delete(item.id);
        await this.loadBids();
      }
    } catch (ex) {
      this.$toast.error("Ошибка при удалении");
    }
    this.deleteLoading = false;
    this.close();
  }

  close() {
    this.displayConfirmDialog = false;
    this.form?.resetValidation();

    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }

}
